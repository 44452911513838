var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-customer"
  }, [!_vm.customer ? [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('SpinnerBeelse', {
    attrs: {
      "multiple": true,
      "color": "orange",
      "size": 200,
      "logo": "B",
      "display-logo": false
    }
  })], 1)], 1)] : [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-row', {
    staticClass: "text-wrap display-1 partTitle mx-0 my-3"
  }, [_vm._v(" " + _vm._s(_vm.customer.name) + " ")])], 1)], 1), _c('v-divider', {
    staticClass: "mt-0 mb-4"
  }), _c('v-row', {
    staticClass: "mx-0 pa-0 mt-2"
  }, [_c('v-card', {
    staticClass: "card-tabs"
  }, [_c('v-tabs', {
    attrs: {
      "slider-color": "orange",
      "grow": "",
      "slider-size": "4"
    }
  }, _vm._l(_vm.tabs, function (tab, key) {
    return _c('v-tab', {
      key: key,
      staticClass: "text-none",
      attrs: {
        "to": tab.route,
        "ripple": false,
        "exact": ""
      }
    }, [tab.icon ? _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(tab.icon) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(tab.name) + " ")], 1);
  }), 1)], 1)], 1), _c('v-tabs-items', {
    staticClass: "tabs-items-content mt-3 elevation-3 px-5 rounded"
  }, [_c('router-view', {
    attrs: {
      "customer": _vm.customer,
      "customer-users": _vm.customerUsers,
      "customer-parts": _vm.customerParts,
      "customer-quotes": _vm.customerQuotes,
      "customer-orders": _vm.customerOrders
    }
  })], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }