<template>
  <div class="page-customer">
    <!--START PAGE HEADER-->
    <!--START PAGE HEADER-->
    <!--START PAGE HEADER-->
    <template v-if="!customer">
      <v-row
        justify="center"
        align="center"
      >
        <v-col align="center">
          <SpinnerBeelse
            :multiple="true"
            color="orange"
            :size="200"
            logo="B"
            :display-logo="false"
          >
          </SpinnerBeelse>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row
        justify="space-between"
        align="center"
      >
        <v-col>
          <v-row class="text-wrap display-1 partTitle mx-0 my-3">
            {{ customer.name }}
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-0 mb-4"></v-divider>
      <!--END PAGE HEADER-->
      <!--END PAGE HEADER-->
      <!--END PAGE HEADER-->
      <v-row class="mx-0 pa-0 mt-2">
        <v-card
          class="card-tabs"
        >
          <v-tabs
            slider-color="orange"
            grow
            slider-size="4"
          >
            <v-tab
              v-for="(tab, key) in tabs"
              :key="key"
              :to="tab.route"
              :ripple="false"
              class="text-none"
              exact
            >
              <v-icon
                v-if="tab.icon"
                small
                class="mr-2"
              >
                {{ tab.icon }}
              </v-icon>
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </v-card>
      </v-row>
      <v-tabs-items class="tabs-items-content mt-3 elevation-3 px-5 rounded">
        <router-view
          :customer="customer"
          :customer-users="customerUsers"
          :customer-parts="customerParts"
          :customer-quotes="customerQuotes"
          :customer-orders="customerOrders"
        ></router-view>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import { ApiErrorParser, SpinnerBeelse } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageCustomer.i18n');

export default {
  name: 'PageCustomer',
  components: { SpinnerBeelse },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      customer: null,
      customerUsers: [],
      customerQuotes: [],
      customerOrders: [],
      customerParts: [],
      loading: true,
      tabs: [
        {
          name: this.$t('information'),
          route: { name: 'CustomerInformation'},
          icon: 'fas fa-info-circle',
        },
        {
          name: this.$t('contacts'),
          route: { name: 'CustomerContacts'},
          icon: 'fas fa-address-book',
        },
        {
          name: this.$t('quotes'),
          route: { name: 'CustomerQuotes'},
          icon: 'fas fa-file-signature',
        },
        {
          name: this.$t('orders'),
          route: { name: 'CustomerOrders'},
          icon: 'fas fa-box-open',
        },
        {
          name: this.$t('parts'),
          route: { name: 'CustomerParts'},
          icon: 'fas fa-cubes',
        }
      ]
    };
  },
  created() {
    this.init();
  },
  updated() {
    if(this.$route.name === 'Customer') {
      this.$router.push(this.tabs[0].route);
    }
  },
  mounted() {
  },
  methods: {
    init() {
      this.loading = true;
      this.getSupplierPrivateWarehouses();
    },
    getSupplierPrivateWarehouses() {
      if(this.$route.params.customerUUID === 'BCM') {
        this.customer = {
          _id: 'BCM',
          name: 'CMT Global Network',
          address: {
            country: 'FR',
            firstname: '',
            lastname: '',
            company: 'Beelse',
            streetAddress: '5 place Nelson Mandela',
            zipCode: '38000',
            city: 'Grenoble',
            state: '',
            phoneNumber: '',
            VATnumber: 'FR95820153781'
          },
          billing: true,
          users: [],
          created: '2018-05-04T10:00:00.106Z'
        };
        this.getSupplierPrivateWarehousesUsers();
      }else{
        /**
         * GET Customer information
         */
        this.$apiInstance
          .getSupplierPrivateWarehouses(this.$route.params.supplierUUID)
          .then(
            (data) => {
              data.forEach(customer => {
                if(customer._id === this.$route.params.customerUUID) {
                  this.customer = customer;
                  return;
                }
              });
            },
            (error) => {
              /**
               * ERROR GET ALL QUOTES
               */
              ApiErrorParser.parse(error);
            }
          )
          .finally(() => {
            this.getSupplierPrivateWarehousesUsers();
          });
      }
    },
    getSupplierPrivateWarehousesUsers() {
      this.loading = true;
      if(this.$route.params.customerUUID === 'BCM') {
        this.customerUsers = [{
          email: 'support@cm-technology.io',
          firstname: 'Support',
          lastname: 'CMT',
          warehouses: ['BCM'],
        }];
        this.getSupplierParts();
      }else{
        /**
         * GET Customer information
         */
        this.$apiInstance
          .getSupplierPrivateWarehousesUsers(this.$route.params.supplierUUID)
          .then(
            (data) => {
              const customerUsers = [];
              data.forEach(user => {
                if(user.warehouses.includes(this.$route.params.customerUUID)) {
                  customerUsers.push(user);
                }
              });
              this.customerUsers = customerUsers;
            },
            (error) => {
              /**
               * ERROR GET ALL QUOTES
               */
              ApiErrorParser.parse(error);
            }
          )
          .finally(() => {
            this.getSupplierParts();
          });
      }
    },
    getSupplierParts() {
      /**
       * GET ALL PARTS (return part that has been homologated by the Supplier)
       */
      this.$apiInstance
        .getSupplierParts(this.$route.params.supplierUUID)
        .then(
          (data) => {
            const customerParts = [];
            data.forEach((part) => {
              if((this.$route.params.customerUUID === 'BCM' && part.homologation.public) || (!part.homologation.public && part.brand_id === this.$route.params.customerUUID)) {
                customerParts.push(part);
              }
            });
            this.customerParts = customerParts;
          },
          (error) => {
            /**
             * ERROR GET ALL QUOTES
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.getSupplierQuotes();
        });
    },
    getSupplierQuotes() {
      /**
       * GET ALL PARTS (return part that has been homologated by the Supplier)
       */
      this.$apiInstance
        .getSupplierQuotes(this.$route.params.supplierUUID)
        .then(
          (data) => {
            const customerQuotes = [];
            data.forEach((quote) => {
              if((this.$route.params.customerUUID === 'BCM' && quote.public) || (!quote.public && quote.brand._id === this.$route.params.customerUUID)) {
                customerQuotes.push(quote);
              }
            });
            this.customerQuotes = customerQuotes;
          },
          (error) => {
            /**
             * ERROR GET ALL QUOTES
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.getSupplierOrders();
        });
    },
    getSupplierOrders() {
      /**
       * GET ALL PARTS (return part that has been homologated by the Supplier)
       */
      this.$apiInstance
        .getSupplierOrders(this.$route.params.supplierUUID)
        .then(
          (data) => {
            const customerOrders = [];
            data.forEach((order) => {
              if((this.$route.params.customerUUID === 'BCM' && order.public) || (!order.public && order.quote.brand._id === this.$route.params.customerUUID)) {
                customerOrders.push(order);
              }
            });
            this.customerOrders = customerOrders;
          },
          (error) => {
            /**
             * ERROR GET ALL QUOTES
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
